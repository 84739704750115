<template>
  <BaseModal name="account-statements-filters" size="md" title="Filtros">
    <b-row>
      <b-col cols="12">
        <b-form novalidate>
          <b-row>
            <b-col cols="5" class="mr-3">
              <b-form-group label="De" label-for="date-in">
                <b-form-input
                  id="date-in"
                  name="date-in"
                  v-model="data.date_in"
                  type="date"
                  placeholder="31/12/2019"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('date-in')">
                  Selecione a data inicial
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Até" label-for="date-out">
                <b-form-input
                  id="date-out"
                  name="date-out"
                  v-model="data.date_out"
                  type="date"
                  placeholder="31/12/2020"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('date-out')">
                  Selecione a data final.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-3" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="secondary" @click="onSubmit"> Filtrar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      data: {
        date_in: null,
        date_out: null,
      },
    };
  },
  methods: {
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("filter", this.data);
          this.$bvModal.hide("account-statements-filters");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables.scss";

.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px $base-color solid;
  width: auto;
  color: #fff;
  background: $base-color;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: $base-color;
    border: 1px $base-color solid;
  }
}
</style>

<template>
  <b-container class="container-table mb-5">
    <!-- Table Header -->
    <b-row class="Table-header mb-1">
      <b-col md="1" class="d-none d-md-block"> #ID</b-col>
      <b-col md="2" class="d-none d-md-block"> Dt. Criação </b-col>
      <b-col md="2" class="d-none d-md-block"> Vl. Bruto </b-col>
      <b-col cols="4" md="1" class="d-none d-md-block"> Taxa </b-col>
      <b-col cols="5" md="2"> Vl. Antecipado </b-col>
      <b-col cols="4" md="2"> Dt. Processada</b-col>
      <b-col cols="3" md="1"> Visualizar</b-col>
    </b-row>

    <!-- Table Body -->
    <template v-if="!loading && antecipationResponse.length > 0">
      <b-row
        v-for="item in antecipationResponse"
        :key="item.id"
        class="Table-body"
      >
        <!-- Id -->
        <b-col md="1" class="d-none d-md-block">
          #{{ item.id }}
        </b-col>
        <!-- Creation Date -->
        <b-col md="2" class="d-none d-md-block">
          {{ item.created_at | datetime }}
        </b-col>
        <!-- Amount Gross -->
        <b-col md="2" class="d-none d-md-block">
          {{ currency.currency_symbol }}
          {{ formatMoney(item.amount) }}
        </b-col>
        <!-- Fee -->
        <b-col cols="4" md="1" class="d-none d-md-block">
          {{ currency.currency_symbol }}
          {{ formatMoney(item.fee) }}
        </b-col>
        <!-- Anticipation Value -->
        <b-col cols="5" md="2">
          {{ currency.currency_symbol }}
          {{ formatMoney(item.total) }}
        </b-col>
        <!-- Date of Processement -->
        <b-col cols="4" md="2">
          {{ item.updated_at | datetime }}
        </b-col>
        <!-- Actions -->
        <b-col cols="3" md="1">
          <div class="Table-body-action"
            @click="openAntecipation(item.id)"
          >
            <img src="@/assets/img/icons/eye.svg" />
          </div>
        </b-col>
      </b-row>
    </template>

    <!-- Loading -->
    <div v-if="loading && antecipationResponse.length == 0"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Feedback Menssage -->
    <b-row v-if="!loading && antecipationResponse.length == 0"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">Nenhuma antecipação encontrada</p>
    </b-row>

  </b-container>
</template>

<script>
import Money from "@/mixins/money";
import RightPreview from "@/components/Seller/Statements/rightPreview";

import AntecipationService from "@/services/resources/AntecipationService";
const serviceAntecipation = AntecipationService.build();

export default {
  name: "TableAnticipations",
  components: { RightPreview },
  mixins: [Money],
  props: {
    tabIndex: {
      required: true,
      type: Number,
      default: 0
    },
    updateAnticipations: {
      required: true,
      type: Boolean,
      default: false
    },
    currency: {
      required: true,
      type: Object,
      default: () => ({
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$"
      })
    }
  },
  watch: {
    updateAnticipations() {
      if(this.updateAnticipations && this.tabIndex === 2) {
        this.pagination.currentPage = 1
        this.pagination.perPage = 10
        this.fetchAnticipations(this.currency.id)
        this.$emit('reset-update-anticipations')
      }
    }
  },
  data() {
    return {
      antecipationResponse: [],
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 15
      },
      permite_antecipacao: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getProfile
    }
  },
  methods: {
    openAntecipation(id_antecipation) {
      const filter = {
        antecipation_group_id: id_antecipation
      }
      this.$emit('open-antecipation', 0, filter)
    },
    fetchAnticipations(currency_id) {
      this.loading = true
      this.antecipationResponse = []

      serviceAntecipation
        .search({ currency_id })
        .then((response) => {
          this.antecipationResponse = response;
          this.$emit('fetch-reports', this.currency.id)
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    }
  }
}
</script>

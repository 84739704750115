<template>
  <BaseModal name="document-number" size="md" title="Adição de CPF/CNPJ">
    <b-row class="mb-3">
      <b-col cols="12">
        <h5 class="mt-2 mb-2">
          Adicione suas informações <br />para realizar o saque
        </h5>
        <b-form-group label="CPF/CNPJ" label-for="cpf_cnpj" class="mt-4">
          <b-form-input
            id="cpf_cnpj"
            name="cpf_cnpj"
            v-model="cpf_cnpj"
            v-validate="'required|cpf_cnpj'"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            autocomplete="off"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('cpf_cnpj') : null"
          >
            Por favor, insira o seu CPF/CNPJ.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-3" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="secondary" @click="onSubmit"> Salvar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import UserService from "@/services/resources/UserService";
import Cookies from "js-cookie";
const service = UserService.build();

export default {
  data() {
    return {
      cpf_cnpj: null,
      submit: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },
  methods: {
    onSubmit() {
      this.submit = true;

      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            id: this.user.id,
            cpf_cnpj: this.cpf_cnpj,
          };

          service
            .update(data)
            .then((response) => {
              this.cpf_cnpj = null;
              this.submit = false;

              this.$bvToast.toast("Seu CPF/CNPJ foi adicionado com sucesso!", {
                title: "Alteração de dados",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });

              this.$store.commit("userSuccess", response);

              let vm = this;
              setTimeout(function() {
                vm.$bvModal.hide("document-number");
                vm.$emit("close");
              }, 100);
            })
            .catch((err) => {
              this.$bvToast.toast("Não foi possível alterar informações.", {
                title: "Alteração de dados",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
  },
};
</script>

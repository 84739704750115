<template>
  <b-container fluid>
    <!-- Table Header -->
    <b-row class="Table-header my-1">
      <b-col cols="2" class="d-none d-md-block"> Data </b-col>
      <b-col cols="6" md="2" class=""> Valor </b-col>
      <b-col cols="3" class="d-none d-md-block"> Conta </b-col>
      <b-col cols="2" class="d-none d-md-block"> Observação </b-col>
      <b-col cols="6" md="3" class=""> Status </b-col>
    </b-row>

    <!-- Table Body -->
    <template v-if="!loading && withdrawals.length > 0">
      <b-row
        v-for="item in withdrawals"
        :key="item.id"
        class="Table-body"
      >
        <!-- Creation Date -->
        <b-col cols="2" class="d-none d-md-block">
          {{ item.created_at | datetime }}
        </b-col>
        <!-- Value -->
        <b-col cols="6" md="2" class="Table-body-price">
          {{ currency.currency_symbol }}
          {{ formatMoney(item.amount, 2, ",") }}
        </b-col>
        <!-- Bank Account -->
        <b-col cols="3" class="d-none d-md-block">
          {{ item.account.account }} - {{ item.account.bank_name }}
        </b-col>
        <!-- Observation -->
        <b-col cols="2" class="Table-body-price d-none d-md-block">
          {{ item.observation }}
        </b-col>
        <!-- Status -->
        <b-col cols="6" md="3" class="">
          <span v-if="item.status == 'REQUESTED'" class="icon-blue">
            Requisitado
          </span>
          <span v-if="item.status == 'ACCEPTED'" class="icon-grenn">
            Transferido
          </span>
          <span v-if="item.status == 'DENIED'" class="icon-red">
            Recusado
          </span>
          <span v-if="item.status == 'REFUNDED'" class="icon-warning">
            Reembolsado
          </span>
        </b-col>
      </b-row>
    </template>

    <Paginate v-if="withdrawals.length > 0"
      :totalPages="pagination.totalPages"
      :activePage="pagination.currentPage"
      @to-page="toPage"
      @per-page="perPage"
    />

    <!-- Loading -->
    <div v-if="loading && withdrawals.length == 0"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Feedback Menssage -->
    <b-row v-if="!loading && withdrawals.length == 0"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">
        Nenhuma solicitação de saque foi encontrada
      </p>
    </b-row>

  </b-container>
</template>

<script>
import Paginate from "@/components/shared/Paginate";
import Money from "@/mixins/money";

import WithdrawService from "@/services/resources/WithdrawService";

const serviceWithdraw = WithdrawService.build();

export default {
  name: "TableWithdraw",
  components: { Paginate },
  mixins: [Money],
  props: {
    tabIndex: {
      required: true,
      type: Number,
      default: 0
    },
    updateWithdrawals: {
      required: true,
      type: Boolean,
      default: false
    },
    currency: {
      required: true,
      type: Object,
      default: () => ({
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$"
      })
    }
  },
  watch: {
    updateWithdrawals() {
      if(this.updateWithdrawals && this.tabIndex === 1) {
        this.pagination.currentPage = 1;
        this.pagination.perPage = 10;
        this.fetchWithdrawals(this.currency.id);
        this.$emit('reset-update-withdrawals');
      }
    }
  },
  data() {
    return {
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 15,
      },
      statement_id: -1,
      total: 0,
      withdrawals: []
    }
  },
  methods: {
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchWithdrawals(this.currency.id);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchWithdrawals(this.currency.id);
    },
    fetchWithdrawals(currency_id) {
      this.loading = true;
      this.withdrawals = [];

      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        currency_id
      };
      this.pagination.totalPages = 1;

      serviceWithdraw
        .search(data)
        .then((response) => {
          this.withdrawals = response.data;
          this.pagination.totalPages = response.last_page;
          this.$emit('fetch-reports', this.currency.id)
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false });
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .Table-body {
    padding: 15px;
  }
  header {
    display: block;
  }
}
</style>
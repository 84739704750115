<template>
  <div>
    <BaseModal
      name="bank-account"
      size="lg"
      :title="$t('seller.statements.modals.bank_account.text_796')"
      @hidden="resetFields"
      @shown="fetchBankAccount"
    >
      <b-row>
        <b-col cols="12">
          <!-- Loading -->
          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>

          <b-form
            novalidate
            v-if="!loading &&
              (status_validation.status == 'APPROVED' ||
                (user.company
                  ? checkStatus(user.company.documents, 'APPROVED').length > 0
                  : false))
            "
          >
            <b-row class="">
              <!-- Bank Number -->
              <b-col cols="12" md="3" class="mr-3">
                <b-form-group :label="$t('seller.statements.modals.bank_account.text_2710')" label-for="bank_number">
                  <b-form-input
                    id="bank_number"
                    name="bank_number"
                    v-model="bank_number"
                    v-validate="'required'"
                    autocomplete="off"
                    placeholder="- - -"
                    type="text"
                    :disabled="disabledBankFilds"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('bank_number')">
                    {{ $t('seller.statements.modals.bank_account.text_797') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Bank Name -->
              <b-col cols="12" md="8" class="banco mt-3 mt-md-0 mb-3">
                <b-form-group :label="$t('seller.statements.modals.bank_account.text_2711')" label-for="bank_name">
                  <b-form-input
                    id="bank_name"
                    name="bank_name"
                    v-model="bank_name"
                    v-validate="'required'"
                    autocomplete="off"
                    :placeholder="$t('seller.statements.modals.bank_account.text_2712')"
                    type="text"
                    :disabled="disabledBankFilds"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('bank_name')">
                    {{ $t('seller.statements.modals.bank_account.text_798') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-col class="search_bank" v-b-modal.bank-account-bank-list>
                  <img src="@/assets/img/icons/search.svg" class="" />
                  {{ $t('seller.statements.modals.bank_account.text_799') }}
                </b-col>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <!-- Agency -->
              <b-col cols="7" class="mr-3">
                <b-form-group :label="$t('seller.statements.modals.bank_account.text_2713')" label-for="agency">
                  <b-form-input
                    id="agency"
                    name="agency"
                    v-model="agency"
                    v-validate="'required'"
                    v-mask="'#########'"
                    autocomplete="off"
                    placeholder="0000"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('agency')">
                    {{ $t('seller.statements.modals.bank_account.text_800') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- DV -->
              <b-col cols="4" class="mb-3">
                <b-form-group label="DV (opcional)" label-for="agency_dv">
                  <b-form-input
                    id="agency_dv"
                    v-mask="'#'"
                    v-model="agency_dv"
                    autocomplete="off"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Account Number -->
              <b-col cols="7" class="mr-3 mb-3">
                <b-form-group :label="$t('seller.statements.modals.bank_account.text_2714')" label-for="account">
                  <b-form-input
                    id="account"
                    name="account"
                    v-model="account"
                    v-validate="'required'"
                    v-mask="'############'"
                    autocomplete="off"
                    placeholder="00000"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('account')">
                    {{ $t('seller.statements.modals.bank_account.text_801') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- DV -->
              <b-col cols="4" class="mb-3">
                <b-form-group label="DV" label-for="account_dv">
                  <b-form-input
                    id="account_dv"
                    name="account_dv"
                    v-model="account_dv"
                    v-validate="'required'"
                    v-mask="'#'"
                    autocomplete="off"
                    placeholder="0"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('account_dv')">
                    {{ $t('seller.statements.modals.bank_account.text_802') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Account Type -->
              <b-col cols="12" md="6" class="mb-3">
                <b-form-group class="mr-3" label="Tipo de conta" label-for="type">
                  <b-form-select
                    v-model="type"
                    name="type"
                    :options="accountTypes"
                    v-validate="'required'"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('type')">
                    {{ $t('seller.statements.modals.bank_account.text_2715') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Modality -->
              <b-col cols="12" md="5">
                <b-form-group :label="$t('seller.statements.modals.bank_account.text_2716')" label-for="modality">
                  <b-form-select
                    v-model="modality"
                    name="modality"
                    :options="accountModality"
                    v-validate="'required'"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('modality')">
                    {{ $t('seller.statements.modals.bank_account.text_803') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Select Coins -->
              <b-col v-if="interAccess" cols="12" md="12" class="mb-4">
                <b-form-group label="Selecione a(s) moeda(s):">
                  <multiselect
                    v-model="selected_currencies"
                    :options="coins"
                    :value="selected_currencies"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder=""
                    label="name"
                    track-by="id"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel="✔️"
                    name="selected_currencies"
                    v-validate="'required'"
                  >
                    <span slot="noOptions">Nenhuma moeda encontrada.</span>
                    <span slot="noResult">Nenhuma moeda encontrada.</span>
                  </multiselect>
                  <b-form-invalid-feedback :state="!errors.has('selected_currencies')">
                    Selecione uma ou mais moedas.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <!-- Feedback Menssages -->
          <div
            class="sem-docs"
            v-if="
              !loading &&
                status_validation.status != 'APPROVED' &&
                (user.company
                  ? (checkStatus(user.company.documents, 'DISAPPROVED')
                      .length ||
                      checkStatus(user.company.documents, 'REQUESTED').length ||
                      !user.company.documents.length) &&
                    !checkStatus(user.company.documents, 'APPROVED').length
                  : true)
            "
          >
            <!-- Menssages of Success -->
            <div v-if="status_validation.status == 'REQUESTED'"
              class="aguardando-analise"
            >
              <p>
                {{ $t('seller.statements.modals.bank_account.text_804') }}
              </p>
              <p>
                {{ $t('seller.statements.modals.bank_account.text_805') }}
              </p>
            </div>
            <!-- Menssages of Error -->
            <div v-else>
              <p>
                {{ $t('seller.statements.modals.bank_account.text_806') }}
              </p>
              <p>
                {{ $t('seller.statements.modals.bank_account.text_807') }}
              </p>
              <BaseButton variant="black" @click="initVerification"
                >{{ $t('seller.statements.modals.bank_account.text_808') }}</BaseButton
              >
            </div>
          </div>

        </b-col>
      </b-row>

      <h5 class="mt-3 mb-4 titulo-contas" v-if="!loading">
        {{ $t('seller.statements.modals.bank_account.text_809') }}
      </h5>

      <!-- Saved Bank Accounts Table -->
      <template v-if="!loading && bankAccounts.length > 0">
        <!-- Saved Bank Accounts Table Header -->
        <b-row class="header-table-contas">
          <b-col cols="12" md="5" class="d-none d-md-block"> {{ $t('seller.statements.modals.bank_account.text_2717') }} </b-col>
          <b-col cols="12" md="2" class="d-none d-md-block"> {{ $t('seller.statements.modals.bank_account.text_2713') }} </b-col>
          <b-col cols="12" md="2" class="d-none d-md-block"> {{ $t('seller.statements.modals.bank_account.text_2714') }} </b-col>
          <b-col cols="12" md="3" class="d-none d-md-block"> {{ $t('seller.statements.modals.bank_account.text_2718') }} </b-col>
          <b-col cols="12" md="3" class="d-md-none d-block">
            {{ $t('seller.statements.modals.bank_account.text_810') }}
          </b-col>
        </b-row>
        <!-- Saved Bank Accounts List -->
        <b-row
          v-for="item in bankAccounts"
          :key="item.id"
          class="BankAccount-item"
          data-anima="top"
        >
          <b-col cols="12" md="5">
            <div>
              • {{ item.bank_number }} -

              {{
                item.bank_name.length > 30
                  ? item.bank_name.substr(0, 30) + "..."
                  : item.bank_name
              }}
            </div>
            <p class="mt-md-2">
              <span class="d-inline">• {{ $t('seller.statements.modals.bank_account.text_2719') }} - </span>
              {{ item.cpf_cnpj }}
            </p>
          </b-col>
          <b-col cols="12" md="2">
            <span class="d-md-none d-inline">• {{$t('seller.statements.modals.bank_account.text_2713') }} - </span>
            {{ item.agency }}
          </b-col>
          <b-col cols="12" md="2">
            <span class="d-md-none d-inline">• {{ $t('seller.statements.modals.bank_account.text_2714') }} - </span>
            {{ item.account }}-{{ item.account_dv }}
          </b-col>
          <b-col cols="12" md="3" class="d-flex">
            <b-button
              type="button"
              class="btn-novo btn-editar"
              @click="loadEdit(item)"
              >{{ $t('seller.statements.modals.bank_account.text_2720') }}
            </b-button>
          </b-col>
        </b-row>

        <p class="info-remove">
          {{ $t('seller.statements.modals.bank_account.text_811') }}
        </p>
      </template>

      <div
        v-if="loading && bankAccounts.length == 0"
        class="table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && bankAccounts.length == 0"
        class="table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t('seller.statements.modals.bank_account.text_812') }}</p>
      </b-row>

      <template v-slot:footer="{ cancel }">
        <BaseButton
          variant="secondary_outline"
          @click="cancel"
          class="mr-3"
          :disabled="loading"
        >
          {{ $t('seller.statements.modals.bank_account.text_2721') }}
        </BaseButton>
        <BaseButton
          variant="primary"
          @click="validateForms"
          :disabled="
            loading ||
              (status_validation.status != 'APPROVED' &&
                (user.company
                  ? !checkStatus(user.company.documents, 'APPROVED').length
                  : true))
          "
        >
          {{ !update ? $t('seller.statements.modals.bank_account.text_813') : $t('seller.statements.modals.bank_account.text_814') }}
        </BaseButton>
      </template>
    </BaseModal>

    <!-- Modal Bank List -->
    <modal-bank-account-bank-list @select="selectBank" />

    <ModalValidation @close="getStatusValidation" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BankAccountService from "@/services/resources/BankAccountService";
import ModalBankAccountRemove from "./bankAccountRemove";
import ModalBankAccountBankList from "./bankAccountBankList";
import ValidationService from "@/services/resources/ValidationService";
import CurrencyCurrenciesService from "@/services/resources/CurrencyCurrenciesService";

const serviceCurrencyCurrencies = CurrencyCurrenciesService.build();
const serviceValidation = ValidationService.build();
const service = BankAccountService.build();

export default {
  components: {
    ModalBankAccountRemove,
    Multiselect,
    ModalBankAccountBankList
  },
  props: {
    value: {
      type: Number,
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
  },
  data() {
    return {
      status_validation: {
        status: "",
        observation: "",
      },
      bank_number: null,
      bank_name: null,
      agency: null,
      agency_dv: null,
      account: null,
      account_dv: null,
      modality: null,
      type: null,
      name: null,
      accountModality: [
        { value: null, text: "Modalidade" },
        { value: "CURRENT", text: "Conta Corrente" },
        { value: "SAVINGS", text: "Conta Poupança" },
      ],
      accountTypes: [{ value: null, text: "Tipo de conta" }],
      bankAccounts: [],
      bank_account_id: -1,
      submit: false,
      loading: false,
      update: false,
      user: {
        documents: [],
        company: {
          documents: [],
        },
      },
      selected_currencies: [],
      coins: [],
      country: "",
      nationalForm: false,
      internationalForm: false,
      internationalData: {
        bank_account_id: null,
        holder_name: "",
        type_international: null,
        iban: "",
        account_number: "",
        bic_swift: "",
        selected_currencies: [],
      },
      bankInternationalAccounts: [],
      submitInternational: false,
      editSelectedInternational: false,
      disabledBankFilds: true,
      interAccess: false
    };
  },
  created() {
    let userInterIds = process.env.USER_INTER.substring(1, process.env.USER_INTER.length - 1).split(',')
    this.interAccess = userInterIds.some(userId => parseInt(userId) === this.$store.getters.getProfile.id)
  },
  methods: {
    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
    initVerification() {
      this.$bvModal.show("identity-validation");
    },
    loadEdit(item) {
      document
        .querySelector("#bank-account")
        .scrollTo({ top: 0, behavior: "smooth" });
      let currencies = [];
      if(item.currencies != null) {
        this.coins.forEach(c => {
          if(item.currencies.some(currency => c.id === currency.id)) currencies.push(c)
        })
      }

      this.nationalForm = true
      this.internationalForm = false
      if (document.querySelector("#bank_number")) {
        document.querySelector("#bank_number").focus()
      }
      this.bank_number = item.bank_number
      this.bank_name = item.bank_name
      this.agency = item.agency
      this.agency_dv = item.agency_dv
      this.account = item.account
      this.account_dv = item.account_dv
      this.name = item.name
      this.bank_account_id = item.id
      this.type = item.account_type
      this.modality = item.type
      this.country = "BR"
      this.selected_currencies = this.interAccess ? currencies : [1]

      this.update = true
    },
    selectBank(item) {
      this.bank_number = item.code;
      this.bank_name = item.name;
    },
    openConfirmRemove(id) {
      this.bank_account_id = id;
      this.$bvModal.show("bank-account-remove");
    },
    resetFields() {
      this.bank_number = null
      this.bank_name = null
      this.agency = null
      this.agency_dv = null
      this.account = null
      this.account_dv = null
      this.type = null
      this.modality = null
      this.name = null
      this.bank_account_id = -1
      this.update = false
      this.selected_currencies = []
      this.nationalForm = false
      this.internationalForm = false
      this.internationalData = {
        holder_name: "",
        type_international: null,
        iban: "",
        account_number: "",
        bic_swift: "",
        selected_currencies: []
      }
      this.country = ""
    },
    /**
     * Triggered when user press submit button, will check which form is being filled. If it's a national, will validate it, if valid, will save the bank account data, if invalid, will return an error message. In the case of the international, the variable "submitInternational" is set, that tells the component of the international bank account form to do the same treatments.
     * @return no return
     * @params no params
     * @author Sandro Souza
     */
    validateForms() {
      // enables bank fields so they can be validated
      this.disabledBankFilds = false
      setTimeout(() => {
        // set currencies to just have ids
        let currencies = []
        this.selected_currencies.forEach(sc => currencies.push(sc.id));

        this.$validator.validateAll().then((result) => {
          if (result) {
            let data = {
              bank_number: this.bank_number,
              bank_name: this.bank_name,
              agency: this.agency,
              agency_dv: this.agency_dv,
              account_dv: this.account_dv,
              account: this.account,
              type: this.modality,
              account_type: this.type,
              name: this.name,
              user_id: this.userId,
              international_account_type: null,
              bic_swift: null,
              currencies: currencies = this.interAccess ? currencies : [1],
              country: "BR"
            };
            this.onSubmit(data);
          }
          else {
            document
              .querySelector("#bank-account")
              .scrollTo({ top: 0, behavior: "smooth" });
            this.$bvToast.toast("Valide os campos obrigatórios", {
              title: "Conta bancária",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
          this.disabledBankFilds = true;
        })
      },500)
    },
    onSubmit(data) {
      this.submit = true
      this.loading = true
      if (!this.update) {
        service
          .create(data)
          .then(() => {
            this.submit = false;
            this.loading = false;
            this.$bvToast.toast(this.$t('seller.statements.modals.bank_account.text_815'), {
              title: this.$t('seller.statements.modals.bank_account.text_2722'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            })

            this.resetFields()
            this.$emit("close")
            this.$bvModal.hide("bank-account")
          })
          .catch(err => console.error(err))
          .finally(() => {
            this.submit = false
            this.loading = false
            this.submitInternational = false
            this.nationalForm = false
            this.internationalForm = false
          });
      } else {
        // On editing
        data.id = this.bank_account_id
        if(data.id === -1) data.id = data.bank_account_id
        if(data.id === undefined) {
          data.id = this.internationalData.bank_account_id
          data.name = JSON.parse(localStorage.user).name
        }
        service
          .update(data)
          .then(() => {
            this.submit = false;
            this.loading = false;
            this.$bvToast.toast(this.$t('seller.statements.modals.bank_account.text_816'), {
              title: this.$t('seller.statements.modals.bank_account.text_2722'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });

            this.resetFields();
            this.$emit("close");
            this.$bvModal.hide("bank-account");
          })
          .catch(err => console.error(err))
          .finally(() => {
            this.submit = false;
            this.loading = false;
            this.submitInternational = false;
            this.nationalForm = false;
            this.internationalForm = false;
          });
      }
    },
    fetchBankAccount() {
      this.loading = true;
      this.getStatusValidation();
      let bankAccounts = [];
      let bankInternationalAccounts = [];

      service
        .search()
        .then((response) => {
          response.forEach(bankIntAcc => {
           if(bankIntAcc.international_account_type != null) bankInternationalAccounts.push(bankIntAcc)
          })
          response.forEach(bankAcc => {
            if(bankAcc.bic_swift == null && bankAcc.account_dv != null) bankAccounts.push(bankAcc)
          })
          bankAccounts = bankAccounts.reverse();
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.bankInternationalAccounts = bankInternationalAccounts;
          this.bankAccounts = bankAccounts;
          this.loading = false;
        })
    },
    getStatusValidation() {
      this.loading = true;
      let data = { id: this.userId };

      serviceValidation
        .read(data)
        .then(response => {
          this.status_validation = response;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }
          if(!this.accountTypes.some(a => a.text === "Conta Pessoal (CPF)")) {
            if (this.status_validation.status == "APPROVED") {
              var accountType = this.accountTypes;
              var result = accountType.filter((x) => x.value === "PERSONAL");
              if (!result.length) {
                this.accountTypes.push({
                  value: "PERSONAL",
                  text: "Conta Pessoal (CPF)",
                });
              }
            } else {
              var accountType = this.accountTypes;
              var result = accountType.filter((x) => x.value === "PERSONAL");
              if (!result.length) {
                this.accountTypes.push({
                  value: "PERSONAL",
                  text: "Conta Pessoal (CPF)",
                  disabled: true,
                });
              }
            }
          }
        })
        .catch(err => console.error(err))
        .finally(() => this.loading = false);
    },
    /**
     * Get currencies from back api where is base currency and set "this.coins" in order: BRL > USD > EUR > GBP
     * @params no params
     * @return no return
     * @author Sandro Souza
     */
    fetchCurrencyCurrencies() {
      serviceCurrencyCurrencies
        .search({ is_base_currency: 1 })
        .then((response) => {
          this.coins = response.sort((a, b) => {
            if (a.id > b.id) return 1
            if (a.id < b.id) return -1
            return 0
          })
        })
        .catch(err => console.error(err))
        .finally(() => {
          if(this.coins[1].currency === 'USD') this.coins[1].currency_symbol = '$'
        })
    }
  },
  mounted() {
    this.loading = true;

    this.$store
      .dispatch("userRequest")
      .then((response) => {
        this.user = response
        this.fetchBankAccount()
        this.fetchCurrencyCurrencies()

        if (
          this.user.company &&
          this.checkStatus(this.user.company.documents, "APPROVED").length
        ) {
          var accountType = this.accountTypes
          var result = accountType.filter((x) => x.value === "PERSONAL")
          if (!result.length) {
            this.accountTypes.push({
              value: "BUSINESS",
              text: "Conta Empresarial (CNPJ)"
            })
          }
        } else {
          var accountType = this.accountTypes
          var result = accountType.filter((x) => x.value === "PERSONAL")
          if (!result.length) {
            this.accountTypes.push({
              value: "BUSINESS",
              text: "Conta Empresarial (CNPJ)",
              disabled: true
            })
          }
        }
      })
      .finally(() => { this.loading = false })
  },
};
</script>

<style lang="scss" scoped>
.BankAccount-item {
  border-top: 1px #ededf0 solid;
  padding: 15px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.banco {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banco .search_bank {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 30px;
  outline: none;
  cursor: pointer;
  margin-top: 30px;
  color: #333;
  font-weight: 600;
}
.search_bank img {
  margin-right: 15px;
}
.titulo-contas {
  font-weight: 600;
  font-size: 16px;
}
.header-table-contas {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}
.btn-novo {
  padding: 5px 10px;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 600;
  margin-right: 10px;
  border: none;
}
.btn-editar {
  // background: rgba(33, 51, 210, 0.1);
  background: transparent;
  color: #2a63ab;
}

@media screen and (max-width: 768px) {
  .banco {
    display: block;
  }
  .banco .search_bank {
    margin: 0;
    padding: 0;
    margin-top: 15px;
    justify-content: flex-end;
  }
}
.info-documento,
.sem-docs p,
.info-remove {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}
.info-remove {
  margin-top: 15px;
}
.sem-docs {
  padding: 10px 0;
}
.sem-docs p {
  font-size: 14px;
}
.sem-docs p + p {
  margin-top: 7px;
}
.sem-docs button {
  margin: 20px 0;
}
.aguardando-analise {
  background: #00236310;
  padding: 20px;
  border-radius: 5px;
}
.aguardando-analise p {
  color: #2a63ab;
}

.custom-margin {
    margin-right: 16px !important;
  }

@media only screen and (max-width: 768px) {
  .custom-margin {
    margin-right: 0px !important;
  }
}
</style>

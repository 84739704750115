<template>
  <div>
    <BaseModal
      name="withdraw-request"
      size="md"
      title="Solicitação de saque"
      @shown="openModal"
      @hidden="close"
    >
      <!-- Loading -->
      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div v-if="!loading && accounts.length && status_validation.status == 'APPROVED'">
        <!-- Avaliable Balance -->
        <div class="item-top justify-content-between">
          <div class="item-top">
            <img src="@/assets/img/icons/money-free.svg" />
            <div class="money">
              <span>Saldo disponível</span>
              <p>
                {{ currency_symbol_avaliable }}
                {{ valor_disponivel ? formatMoney(valor_disponivel) : "0,00" }}
              </p>
            </div>
          </div>
        </div>
        <!-- Anticipate -->
        <div class="item-top item-antecipa" v-if="permite_antecipacao && status_antecipation !== 'REQUESTED'">
          <!-- Anticipate Amount -->
          <div class="item-top">
            <img src="@/assets/img/icons/money-anticipation.svg" />
            <div class="money">
              <span>Disponível para Antecipação</span>
              <p>
                {{ currency_symbol_avaliable }}
                {{ valor_antecipavel ? formatMoney(valor_antecipavel) : "0,00" }}
              </p>
            </div>
          </div>
          <!-- Want Anticipate -->
          <div id="quero-antecipar">
            <BaseButton
              v-if="permite_antecipacao"
              :disabled="0 >= valor_antecipavel"
              variant="black"
              class="btn-saldo"
              @click="openAntecipation"
            >
              ⚡ Quero antecipar
            </BaseButton>
          </div>
          <!-- Want Anticipate Error Menssage -->
          <b-tooltip v-if="0 >= valor_antecipavel"
            target="quero-antecipar"
            title="Você não possui valores para antecipar"
          />
        </div>
        <!-- Feedback Menssage -->
        <div class="text-info mt-3">
          <div v-if="status_antecipation === 'REQUESTED'"
            class="aprovacao btn-2 mt-2 mb-3"
          >
            <img src="@/assets/img/icons/info-prod.svg" />
            Você tem uma antecipação em aprovação no valor de 
            {{ lastAntecipationCurrencySymbol }}
            {{ formatMoney(last_antecipation.amount) }}. dentro de poucas horas você receberá um e-mail, e em caso de aprovação, o dinheiro ficará disponível para saque imediato.
          </div>
          <div v-if="status_antecipation === 'REFUSED'"
            class="aprovacao DISAPPROVED btn-2 mt-2 mb-3"
          >
            <img src="@/assets/img/icons/info-prod-reprovado.svg" />
            Sua ultima antecipação foi recusada, foram enviados mais detalhes no
            seu E-mail.
          </div>
        </div>
        <!-- Withdrawal Amount -->
        <div class="mb-3 mt-4">
          <h5 class="topico">Qual o valor do saque?</h5>
          <b-form novalidate>
            <b-form-group label="" label-for="amount">
              <money
                id="amount"
                v-model="amount"
                v-bind="money"
                v-validate="'required'"
                autocomplete="off"
                disable
              ></money>
              <b-form-invalid-feedback :state="!amount_error">
                Preencha o valor corretamente
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
        <!-- Select Bank Account -->
        <h5 class="topico">Para qual conta você quer transferir?</h5>
        <div class="mb-3 d-block d-md-flex align-items-center">
          <b-form novalidate>
            <b-form-group label="" label-for="bank_account">
              <b-form-select
                class="select-contas-bancarias"
                v-model="bank_account"
                name="bank_account"
                :options="accounts"
                v-validate="'required'"
                @change="updateBankAccount()"
              ></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('bank_account')">
                Por favor, selecione uma conta bancária
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
          <b-tooltip target="btn-conta" title="Adicionar conta bancária" />
          <BaseButton
            id="btn-conta"
            variant="link-info"
            class="btn-conta-corrente"
            @click="openBanks"
          >
            <img src="@/assets/img/icons/card-outline.svg" />
            <span class="d-md-none d-block ml-2">Adicionar conta</span>
          </BaseButton>
        </div>
      </div>
      <!-- Add Bank Account -->
      <div v-if="!accounts.length && status_validation.status == 'APPROVED'"
        class="sem-contas"
      >
        <p>Você não possui contas bancárias cadastradas.</p>
        <BaseButton variant="info3" class="mt-3" v-b-modal.bank-account>
          Cadastrar conta bancária agora
        </BaseButton>
      </div>
      <!-- Withdrawal Details -->
      <div v-if="!pre_infos && status_validation.status == 'APPROVED'"
        class="detalhes-saque"
        data-anima="top"
      >
        <h5 class="topico topico_detalhes">Você está sacando</h5>
        <!-- Account Details National -->
        <div class="item-detalhe">
          <span>Dados da conta</span>
          <div class="info-detalhe">
            <p>Documento</p>
            <p class="value-info">{{ conta_selecionada.cpf_cnpj }}</p>
          </div>
          <div class="info-detalhe">
            <p>Instituição</p>
            <p class="value-info">{{ conta_selecionada.bank_name }}</p>
          </div>
          <div class="info-detalhe">
            <p>Agência</p>
            <p class="value-info">{{ conta_selecionada.agency }}</p>
          </div>
          <div class="info-detalhe">
            <p>Conta {{ conta_selecionada.type == "CURRENT" ? "Corrente" : "Poupança" }}</p>
            <p class="value-info">{{ conta_selecionada.account }}-{{ conta_selecionada.account_dv }}</p>
          </div>
        </div>

        <!-- Amount Details -->
        <div class="item-detalhe" v-if="amount">
          <span>Transferência</span>
          <div class="info-detalhe">
            <p>Valor solicitado</p>
            <p class="value-info">
              {{ currency_symbol_avaliable }}
              {{ formatMoney(amount) }}
            </p>
          </div>
          <div v-if="!loadingConvertion && selected_currency != null && wallet_currency != null && (selected_currency != wallet_currency)"
            class="info-detalhe"
          >
            <p>Valor convertido</p>
            <p class="value-info">
              {{ currency_symbol_details }}
              {{ formatMoney(currency_conversion) }}
            </p>
            <!-- Loading -->
            <div v-if="loadingConvertion" class="d-flex justify-content-center">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </div>
          <div class="info-detalhe">
            <p>Taxa de Saque</p>
            <p class="value-info taxa-info">
              - {{ currency_symbol_avaliable }}
              {{ formatMoney(taxa_saque) }}
            </p>
          </div>
          <div v-if="selected_currency != null && wallet_currency != null && (selected_currency != wallet_currency)" class="info-detalhe">
            <p>Taxa de Conversão</p>
            <p class="value-info taxa-info">
              - {{ currency_symbol_details }}
              {{ formatMoney(currency_fee) }}
            </p>
          </div>
          <div v-if="selected_currency != null && wallet_currency != null" class="info-detalhe">
            <p>Será transferido</p>
            <p class="value-info">
              {{ currency_symbol_details }}
              {{ selected_currency.id === wallet_currency.id
                ? formatMoney(amount - taxa_saque)
                : formatMoney(currency_conversion - taxa_saque - currency_fee) }}
            </p>
          </div>
        </div>
        <div class="item-detalhe" v-else>
          <span>___ Preencha corretamente o valor que deseja sacar </span>
        </div>
      </div>
      <!-- Identity Verification -->
      <div v-if="!loading && status_validation.status != 'APPROVED'"
        class="verificao"
      >
        <div v-if="status_validation.status == 'REQUESTED'">
          <p>
            Sua verificação de identidade já foi enviada para nossa equipe e
            está em processo de análise.
          </p>
          <p>
            Aguarde, em breve receberá o retorno com o resultado da análise.
          </p>
        </div>
        <div v-else>
          <p>Vimos que sua verificação de identidade ainda não foi feita.</p>
          <p>
            Para adicionar uma conta bancária, primeiro é necessário que faça a
            verificação de identidade.
          </p>
          <BaseButton variant="black" @click="initVerification">
            Iniciar a verificação de identidade
          </BaseButton>
        </div>
      </div>
      <!-- Footer -->
      <template v-slot:footer="{ cancel }">
        <BaseButton
          variant="secondary_outline"
          class="mr-4"
          @click="cancel"
          :disabled="loading"
        >
          Cancelar
        </BaseButton>
        <div id="btn-sacar">
          <BaseButton
            variant="link-info"
            @click="onSubmit"
            :disabled="loading || pre_infos || amount < 20 || wallet_currency == null"
          >
            Solicitar saque
          </BaseButton>
        </div>
        <b-tooltip
          v-if="amount < 20"
          target="btn-sacar"
          :title="'Valor minímo para saque é de ' + currency_symbol_avaliable + ' 20,00'"
        />

        <b-tooltip
          v-if="pre_infos && amount > 19"
          target="btn-sacar"
          title="Selecione a conta para transferência"
        />
      </template>
    </BaseModal>

    <Antecipation
      :antecipation="valor_antecipavel"
      :antecipation_fee="antecipation_fee"
      :antecipation_status="status_antecipation"
      :currency="wallet_currency"
      @close="fetchReports(wallet_currency.id)"
      @pending="setPending"
    />
    <ModalValidation @close="getStatusValidation" />
  </div>
</template>
<script>
import { EventBus } from "@/main.js";
import { Money } from "v-money";
import FormatMoney from "@/mixins/money";
import Utils from "@/mixins/utils";

import WithdrawService from "@/services/resources/WithdrawService";
import BankAccountService from "@/services/resources/BankAccountService";
import DashboardService from "@/services/resources/DashboardService";
import GlobalSettings from "@/services/resources/GlobalSettings";
import Antecipation from "./antecipation";
import ValidationService from "@/services/resources/ValidationService";
import CurrencyCurrenciesService from "@/services/resources/CurrencyCurrenciesService";
import CurrencyConversionService from "@/services/resources/CurrencyConversionService";

const serviceWithdraw = WithdrawService.build();
const serviceBankAccount = BankAccountService.build();
const serviceStatement = DashboardService.build();
const serviceGlobalSettings = GlobalSettings.build();
const serviceValidation = ValidationService.build();
const serviceCurrencyCurrencies = CurrencyCurrenciesService.build();
const serviceCurrencyConversion = CurrencyConversionService.build();

export default {
  name: "Withdraw",
  components: {
    Money,
    Antecipation,
  },
  mixins: [Utils,FormatMoney],
  props: {
    permite_antecipacao: {},
    status_antecipation:{},
    last_antecipation:{},
    lastAntecipationCurrencySymbol: {
      required: false,
      type: String,
      default: "R$"
    },
    antecipation_fee: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      status_validation: {
        status: "",
        observation: "",
      },
      pre_infos: true,
      valor_disponivel: 0,
      valor_antecipavel: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      amount: 0,
      amount_error: false,
      bank_account: "",
      accounts: [
        {
          value: "",
          text: "Selecione uma conta",
        },
      ],
      contas: [
        {
          _infos: {
            cpf_cnpj: "",
            bank_name: "",
            agency: "",
            account: "",
            type: "",
          },
        },
      ],
      conta_selecionada: {
        cpf_cnpj: "",
        bank_name: "",
        agency: "",
        account: "",
        type: "",
        account_dv: "",
      },
      loading: false,
      loadingConvertion: false,
      submit: false,
      selected_currency: null,
      statusAntecipationLocal: "",
      coins: [],
      base_coins: [],
      currency_symbol_avaliable: "R$",
      currency_symbol_details: "R$",
      currency_fee: 0,
      currency_conversion: 0,
      wallet_currency: null,
      interAccess: false
    };
  },
  watch: {
    amount() {
      this.amount_error = this.amount ? false : true
      this.fetchCurrencyConversion();
    },
  },
  methods: {
    setPending(){
      this.statusAntecipationLocal = this.status_antecipation
      this.statusAntecipationLocal = 'REQUESTED';
      this.$emit("pending");
    },
    close() {
      this.resetData();
      this.$emit("close");
    },
    fetchGlobalSettings() {
      let data = {
        keys: "SELLER_WITHDRAW_FEE",
      };
      serviceGlobalSettings.search(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "SELLER_WITHDRAW_FEE") {
            this.taxa_saque = +item.value;
          }
        });
      });
    },
    initVerification() {
      this.$bvModal.show("identity-validation");
    },
    getStatusValidation() {
      this.loading = true;
      let data = { id: this.userID };
      serviceValidation
        .read(data)
        .then(response => {
          this.status_validation = response;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }
        })
        .catch(err =>  console.error(err))
        .finally(() => this.loading = false);
    },
    fetchReports(currency_id) {
      if(!currency_id) currency_id = 1;
      this.loading = true;
      let data = {
        types: ["statements"],
        currency_id
      };

      serviceStatement
        .search(data)
        .then((response) => {
          this.valor_disponivel = response.statementsAvailable;
          this.valor_antecipavel = response.statementsCanBeAntecipated;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openAntecipation() {
      this.$bvModal.show("antecipation");
    },
    openBanks() {
      //redirect to /minha-conta with tab "contas bancárias" selected and bankAccount query param
      this.$router.push({ name: "MyAccount", query: { tab: 2 } });
    },
    updateBankAccount() {
      if (this.bank_account) {
        // get account object from id
        const conta = this.contas.find(item => item.id == this.bank_account);
        // set select currency options "this.coins" with bank currencies avaliable
        let coins = [];
        if(conta.currencies) {
          this.base_coins.forEach(coin => {
            if(conta.currencies.some(currency => coin.id === currency.id)) coins.push(coin)
          })
        } else coins = [];
        this.coins = coins;
        this.pre_infos = false;
        this.conta_selecionada = {
          cpf_cnpj: conta.cpf_cnpj,
          bank_name: conta.bank_name,
          agency: conta.agency,
          account: conta.account,
          account_dv: conta.account_dv,
          type: conta.type,
          name: conta.name,
          international_type: conta.international_type,
          bic_swift: conta.bic_swift,
          currencies: conta.currencies,
          country: conta.country
        };
        this.currency_symbol_details = this.wallet_currency.currency_symbol;
      } else {
        this.pre_infos = true
        this.currency_symbol_avaliable = "R$";
        this.currency_symbol_details = "R$";
      }
      this.selected_currency = null
    },
    roundValue(num, places) {
      return +parseFloat(num).toFixed(places);
    },
    onSubmit() {
      let typeDoc = "cpf"; // if bank account is international, typeDoc are "cpf" by default
      // if bank account is national "this.conta_selecionada.cpf_cnpj" will exists and bring the selected typeDoc
      if(this.conta_selecionada.cpf_cnpj != undefined) {
        typeDoc = this.conta_selecionada.cpf_cnpj.length > 14 ? "cnpj" : "cpf"
      }
      // validate all required fields
      this.$validator.validateAll().then((result) => {
        if (result) {
          // validate withdrow value
          if (!this.amount) {
            this.amount_error = true;
            this.$bvToast.toast(
              "Preencha o valor corretamente e tente novamente", {
                title: "Saque",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            return;
          }
          // validate if withdrow value is smaller than available balance
          if (this.amount > this.roundValue(this.valor_disponivel, 2)) {
            this.amount_error = true;
            this.$bvToast.toast(
              "O saldo disponível é menor do que o valor do saque", {
                title: "Saque",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            return;
          }
          // validate if physical person and  withdrow value is smaller than 2000
          if (typeDoc == "cpf" && this.amount > 2000) {
            this.$bvToast.toast(
              `Conta de Pessoa Física é autorizado sacar apenas ${this.wallet_currency ? this.wallet_currency.currency_symbol : "R$"} 2.000,00 por mês`,
              {
                title: "Saque",
                variant: "info",
                autoHideDelay: 9000,
                appendToast: true,
              }
            );

            this.amount_error = true;
            return;
          }

          this.loading = true;
          this.amount_error = false;

          let data = {
            data: {
              amount: {
                requested_amount: this.amount
              },
              account_withdrawal: {
                account_id: this.bank_account,
                wallet_currency_id: this.wallet_currency.id
              }
            }
          };

          serviceWithdraw
            .create(data)
            .then(() => {
              this.amount = 0;
              this.bank_account = "";
              this.pre_infos = true;

              this.$bvToast.toast(
                "Sua solicitação de saque foi feita com sucesso!",
                {
                  title: "Saque",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              this.resetData();
              this.$emit("close");
              this.$bvModal.hide("withdraw-request");
            })
            .catch(err => console.error(err))
            .finally(() => { this.loading = false });
        }
      });
    },
    openModal() {
      if(JSON.parse(localStorage.user).country) {
        if(JSON.parse(localStorage.user).country.base_currency) {
          this.wallet_currency = JSON.parse(localStorage.user).country.base_currency
        }
      }
      this.fetchReports(this.wallet_currency.id)
      this.currency_symbol_avaliable = this.wallet_currency.currency_symbol;
      this.money.prefix = this.wallet_currency.currency_symbol + " ";
      this.fetchAccounts(this.wallet_currency.id);
    },
    fetchAccounts(currency_id) {
      this.loading = true
      this.accounts = [{ value: "", text: "Selecione uma conta" }]
      let accounts_details = []
      let data = {
        currency_id: currency_id = this.interAccess ? currency_id : 1
      }
      serviceBankAccount
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            if(element.last_document?.status === 'APPROVED') {
              if(element.bic_swift != "" && element.bic_swift != null) {
                this.accounts.push({
                  value: element.id,
                  text: element.account + " - " + element.name,
                });
                accounts_details.push({
                  id: element.id,
                  name: element.name,
                  international_type: element.international_account_type,
                  account: element.account,
                  bic_swift: element.bic_swift,
                  country: element.country.country_code,
                  currencies: element.currencies
                });
              } else {
                this.accounts.push({
                  value: element.id,
                  text: element.account + " - " + element.bank_name,
                });
                accounts_details.push({
                  id: element.id,
                  cpf_cnpj: element.cpf_cnpj,
                  bank_name: element.bank_name,
                  agency: element.agency,
                  account: element.account,
                  account_dv: element.account_dv,
                  type: element.type,
                  currencies: element.currencies
                });
              }
            }
          });
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.contas = accounts_details;
          this.loading = false;
        });
    },
    /**
     * Get currencies from back api where is base currency and set "this.coins" in order: BRL > USD > EUR > GBP
     * @params no params
     * @return no return
     * @author Sandro Souza
     */
    fetchCurrencyCurrencies() {
      let data = { is_base_currency: 1 };
      serviceCurrencyCurrencies
        .search(data)
        .then((response) => {
          this.coins = response.sort((a, b) => {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          })
          this.base_coins = response.sort((a, b) => {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if(this.coins[1].currency === 'USD') this.coins[1].currency_symbol = '$'
        });
    },
    updateCurrency() {
      if(this.selected_currency) this.currency_symbol_details = this.selected_currency.currency_symbol;
      this.fetchCurrencyConversion();
    },
    resetData() {
      this.currency_symbol = "R$";
      this.money.prefix = "R$";
      this.selected_currency = null;
      this.bank_account = "";
      this.conta_selecionada = {};
      this.amount = 0;
    },
    fetchCurrencyConversion() {
      if(this.wallet_currency && this.selected_currency){
        this.loadingConvertion = true;
        let data = {
          currency_from: this.wallet_currency.currency,
          currency_to: this.selected_currency.currency,
          amount: this.amount
        };
        serviceCurrencyConversion
          .create(data)
          .then((response) => {
            this.currency_conversion = parseFloat(response.result);
          })
          .catch(err => console.error(err))
          .finally(() => {
            this.loadingConvertion = false;
            this.updateFee();
          })
      }
    },
    updateFee() {
      let currency_fee = {};
      currency_fee = this.conta_selecionada.currencies.find(c => c.id === this.selected_currency.id).fee_conversion
      if(currency_fee != null && this.selected_currency != this.wallet_currency) {
        if(currency_fee.type === "fixed") this.currency_fee = currency_fee.fee;
        else this.currency_fee = this.currency_conversion * currency_fee.fee;
      } else this.currency_fee = 0;
    },
    updateCoin() {
      if(this.wallet_currency) this.currency_symbol_avaliable = this.wallet_currency.currency_symbol
      this.money.prefix = this.currency_symbol_avaliable + " "
      this.fetchReports(this.wallet_currency.id)
      this.fetchCurrencyConversion()
      this.fetchAccounts(this.wallet_currency.id)
    },
    setColors(coin) {
      if(coin === 'Real Brasileiro') return "background-color: rgba(0, 132, 92, 1); color: rgba(255, 203, 55, 1)";
      if(coin === 'Dólar Americano') return "background-color: rgba(255, 203, 55, 1); color: rgba(0, 0, 0, 1)";
      if(coin === 'Euro') return "background-color: rgb(40 30 176); color:rgba(255, 203, 55, 1)";
      if(coin === 'Libra') return "background-color: rgba(0, 0, 0, 1); color: rgba(255, 203, 55, 1)";
      return "background-color: #21a56e; color: rgba(255, 203, 55, 1)"
    },
  },
  created() {
    this.wallet_currency = {
      id: 1,
      name: "Real Brasileiro",
      currency: "BRL",
      currency_symbol: "R$",
      created_at: "2022-06-27T20:11:50.000000Z",
      updated_at: "2022-06-27T20:11:50.000000Z",
      is_base_currency: 1,
    };
    EventBus.$on("updateBanks", () => this.fetchAccounts(this.wallet_currency.id));
    this.getStatusValidation();
    this.fetchGlobalSettings();
    this.fetchCurrencyCurrencies();
    let userInterIds = process.env.USER_INTER.substring(1, process.env.USER_INTER.length - 1).split(',')
    this.interAccess = userInterIds.some(userId => parseInt(userId) === this.userID)
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables.scss";

.amount-available {
  font-size: 34px;
  color: $base-color;
  font-weight: 600;
}

.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px $base-color solid;
  width: auto;
  color: #fff;
  background: $base-color;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: $base-color;
    border: 1px $base-color solid;
  }
}
.item-valor {
  display: block;
  padding: 15px 0;
}
.item-valor + .item-valor {
  border-top: 1px solid #ededf0;
}
.item-valor span {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
}
.item-valor p {
  font-size: 28px;
  font-weight: 600;
  margin: 10px 0;
}
@media screen and (max-width: 768px) {
  .item-valor p {
    font-size: 13px;
  }
}
.saldo-livre {
  color: #2a63ab;
}
.item-valor p.taxa {
  font-size: 18px;
}
.btn-conta-corrente {
  display: flex;
  align-items: center;
  color: #333;
  background: #f7f7f7;
  font-weight: 600;
  font-size: 14px;
  padding: 0 20px !important;
  margin-left: 15px;
  margin-top: -15px;
}
@media screen and (max-width: 768px) {
  .btn-conta-corrente {
    margin-left: 0;
    margin-top: 0px;
    font-size: 13px;
  }
}
.btn-conta-corrente img {
  filter: brightness(1) invert(1);
  min-width: 18px;
}
.select-contas-bancarias {
  width: 350px;
  max-width: 100%;
}

// novos saque
.topico {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 15px;
}

.saldo-info {
  font-size: 13px;
  color: #81858e;
  margin-bottom: 20px;
}
.saldo-info span {
  font-weight: 600;
  color: #2a63ab;
}

.text-info .info {
  font-size: 13px;
  color: #2a63ab;
  margin-bottom: 20px;
}

.text-info .error {
  font-size: 13px;
  color: #ff0c37;
  margin-bottom: 20px;
}

.detalhes-saque .item-detalhe {
  // background: red;
  padding: 15px 10px;
}
.detalhes-saque .item-detalhe + .item-detalhe {
  margin-top: 10px;
  border-top: 1px solid #ededf0;
}
.detalhes-saque .item-detalhe span {
  font-size: 12px;
  color: #2a63ab;
  margin-bottom: 5px;
  display: block;
}
.detalhes-saque .item-detalhe .info-detalhe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
.detalhes-saque .item-detalhe .info-detalhe + .info-detalhe {
  margin-top: 5px;
}
.detalhes-saque .item-detalhe .info-detalhe p {
  color: #81858e;
}
.detalhes-saque .item-detalhe .info-detalhe p.value-info {
  color: #333;
}

.topico_detalhes {
  margin-left: 10px;
  margin-bottom: 0;
}
.taxa-info {
  color: #ff0c37 !important;
}

.sem-contas {
  text-align: center;
  padding: 15px;
}
.sem-contas p {
  color: #81858e;
  font-size: 14px;
}

.item-top {
  display: flex;
  align-items: center;
  gap: 15px;
}
.item-top img {
  max-width: 35px;
}
.item-top + .item-top {
  margin-top: 20px;
}
.item-top span {
  font-size: 12px;
  font-weight: normal;
  color: #81858e;
}
.item-top p {
  font-size: 14px;
  font-weight: 600;
  // margin: 3px 0;
}

.item-top p.visibled {
  background: transparent;
  color: #333;
}
.item-antecipa {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .item-antecipa {
    display: block;
  }
  .item-antecipa #quero-antecipar {
    margin-top: 15px;
  }
}
.btn-saldo {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.verificao p {
  color: #81858e;
  font-size: 14px;
}
.verificao button {
  margin-top: 30px;
}

.btn-2 {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: flex-start;
}
.aprovacao img {
  margin-right: 10px;
}
.DISAPPROVED {
  background: rgba(255, 12, 55, 0.1);
  color: #ff0c37;
}
.APPROVED {
  background: rgba(0, 33, 99, 0.1);
  color: #002363;
}
.chip {
  width: 30px;
  height: 30px;
  border:  1px solid transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
</style>
